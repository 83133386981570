
import { Form as Validation } from 'vee-validate'
import { defineComponent, ref } from 'vue'
import PageAuth from '@/components/layout/PageAuth.vue'
import TmButton from '@/components/shared/TmButton.vue'
import TmFormLine from '@/components/shared/TmFormLine.vue'
import SignWithButtons from '@/components/pages/auth/SignWithButtons.vue'

export default defineComponent({
  name: 'SignupInvite',
  components: {
    TmFormLine,
    TmButton,
    PageAuth,
    Validation,
    SignWithButtons,
  },
  setup() {
    const email = ref('')

    return {
      email,
    }
  },
})
